import $ from 'jquery'

function shrinkNav () {
  let scrollLimit = 300
  // let navHeight = 44
  if ($(document).scrollTop() > scrollLimit) {
    $('header .header--wrapper').addClass('is--small')
  } else {
    $('header .header--wrapper').removeClass('is--small')
  }
}

export default function init () {
  $(document).on('scroll', shrinkNav)
}
