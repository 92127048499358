import $ from 'jquery'

export default function init () {
  // if ( window.location.hash ) scroll(0,0);
  // setTimeout( function() { scroll(0,0); }, 1);

  // any position
  $(function () {
    // $('.scroll').on('click', function(e) {
    // e.preventDefault();
    // $('html, body').animate({
    //   scrollTop: $($(this).attr('href')).offset().top + 'px'
    // }, 1000, 'swing');
    // });

    if (window.location.hash) {
      // eslint-disable-next-line
      scroll(0, 0)
      $('html, body').animate({
        scrollTop: $(window.location.hash).offset().top - 175
      }, 1000, 'swing')
    }
  })
}
