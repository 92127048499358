import $ from 'jquery'

export default function init () {
  console.log('init')

  $(document).on('click', '.book-image', function (e) {
    e.preventDefault()
    let toggleID = $(this).data('id')

    $('.book-image').removeClass('active')
    $(this).addClass('active')

    $('.buecher-box').removeClass('active')
    $('.buecher-box[data-book-id="' + toggleID + '"]').addClass('active')

    // $('.buecher-box').fadeOut()
    // $('.buecher-box[data-book-id="' + toggleID + '"]').fadeIn()
  })
}
