import $ from 'jquery'

export default function init () {
  $(document).ready(function () {
    if (window.matchMedia('(min-width: 768px)').matches) {
      if ($('.header-image-twig').height() === 0 || !($('.header-image-twig').length)) {
        $('.main-content').css({'padding-top': '0'})
        if ($('.main-content').children(':first').hasClass('header-image-twig')) {
          $('.main-content').children(':nth-child(2)').css({'padding-top': '20px'})
        } else {
          $('.main-content').children(':first').css({'padding-top': '20px'})
        }
      }
    }
  })
}
